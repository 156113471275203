(function ($) {
  bindMarketSelectorEvents();
  bindMainMenuDropdownEvents();
  bindAccountSelectorDropdown();
  showAddToCartQuantityForEDometic();
  getFreightPriceLimit();
  bindConfirmAlertMessageButton();
  $(document).on('click', '.yamm .dropdown-menu', function (e) {
    e.stopPropagation(); // Makes it possible to click inside the megamenu without closing it.
  });

  //Move search on mobile
  if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
    $("#searchForm").appendTo("#mobileSearch");
  }

  //Hide menu when clicking outside the navigation container
  $(document).click(function (event) {
    var clickover = $(event.target);
    var $navbar = $(".navbar-collapse");
    var _opened = $navbar.hasClass("in");
    if (_opened === true && !clickover.hasClass("navbar-toggle")) {
      $navbar.collapse('hide');
    }
  });
  var visibilityClassNames = "hidden-xs hidden-sm",
    openClassName = "open";

  // Expand product categories when click on solution category
  var $solutionCatLink = $(".navigationMainComponent-solutionCatLink.is-Expandable"),
    $productCatList = $(".navigationMainComponent-productCatList");
  $solutionCatLink.on('click', function (e) {
    if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
      var $this = $(this);
      var $currentProductCatList = $(this).next(".navigationMainComponent-productCatList");
      if ($currentProductCatList.hasClass(visibilityClassNames)) {
        $productCatList.addClass(visibilityClassNames); // Reset sibling lists to default state
        $currentProductCatList.removeClass(visibilityClassNames); // Remove visibility classes to make selected item visible and add "is open" class to style carets.
        $solutionCatLink.removeClass(openClassName); // Remove "open" class on siblings.
        $this.addClass(openClassName); // Remove visibility classes to make selected item visible and add "is open" class to style carets.
      } else {
        $currentProductCatList.addClass(visibilityClassNames); // Add visibility classes to close the list.
        $this.removeClass(openClassName);
      }
      e.preventDefault();
    }
  });

  // Expand product types when click on product category
  var $productCatLink = $(".navigationMainComponent-productCatLink.is-Expandable"),
    $productTypeList = $(".navigationMainComponent-productTypeList"),
    $buyingGuideList = $(".navigationMainComponent-buyingGuideList ");
  $productCatLink.on('click', function (e) {
    if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
      var $this = $(this);
      var $currentProductTypeList = $(this).next(".navigationMainComponent-productTypeList");
      if ($currentProductTypeList.hasClass(visibilityClassNames)) {
        $productTypeList.addClass(visibilityClassNames); // Reset sibling lists to default state
        $buyingGuideList.addClass(visibilityClassNames); // Reset sibling lists to default state
        $currentProductTypeList.removeClass(visibilityClassNames); // Remove visibility classes to make selected item visible
        $productCatLink.removeClass(openClassName);
        $this.addClass(openClassName); // Remove visibility classes to make selected item visible
      } else {
        $currentProductTypeList.addClass(visibilityClassNames); // Add visibility classes to close the list.
        $this.removeClass(openClassName);
      }
      e.preventDefault();
    }
  });

  // Expand product types when click on product category
  var $buyingGuideLink = $(".navigationMainComponent-buyingGuideLink.is-Expandable"),
    $buyingGuideProductTypeList = $(".navigationMainComponent-buyingGuideProductTypeList");
  $buyingGuideLink.on('click', function (e) {
    if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
      var $this = $(this);
      var $currentProductTypeList = $(this).next(".navigationMainComponent-buyingGuideProductTypeList");
      if ($currentProductTypeList.hasClass(visibilityClassNames)) {
        $buyingGuideProductTypeList.addClass(visibilityClassNames); // Reset sibling lists to default state
        $currentProductTypeList.removeClass(visibilityClassNames); // Remove visibility classes to make selected item visible
        $buyingGuideProductTypeList.removeClass(openClassName);
        $this.addClass(openClassName); // Remove visibility classes to make selected item visible
      } else {
        $currentProductTypeList.addClass(visibilityClassNames); // Add visibility classes to close the list.
        $this.removeClass(openClassName);
      }
      e.preventDefault();
    }
  });
  bindAddToCartEvent();
})(jQuery);
function bindMarketSelectorEvents() {
  $(".marketSelector").click(function (e) {
    e.preventDefault();
    var $marketSelectorPlaceholder = $(".marketSelectorList"),
      theme = $("body").data("theme");
    if ($marketSelectorPlaceholder.html().length === 0 || $marketSelectorPlaceholder.hasClass("has-error")) {
      $marketSelectorPlaceholder.html("<li class=\"u-overflowHidden text-center\"><img src=\"/ui/" + theme + "/img/loader.svg\" class=\"navigationMainComponent-marketSelectorLoader u-loader\" /></li>");
      var sitecoreItem = $("body").data("sc-item");
      var sitecoreLanguage = $("body").data("sc-language");
      var requestUrl = window.location;
      $.ajax({
        type: "get",
        url: "/get-marketlanguageselectorhtml" + "?sitecoreItemId=" + sitecoreItem + "&sitecoreLanguageId=" + sitecoreLanguage + "&requestUrl=" + requestUrl,
        error: function error() {
          $marketSelectorPlaceholder.html("<li class=\"navigationMainComponent-xhrError\">ERROR: Could not load market selector</li>");
        },
        success: function success(data) {
          $marketSelectorPlaceholder.html(data);
          setEuropeCssColumnStyling();
        }
      });
    }
  });
}
function bindMainMenuDropdownEvents() {
  var $mainMenuDropdowns = $("#navbar"),
    $body = $("body");
  $mainMenuDropdowns.on('show.bs.collapse', function () {
    $body.addClass("u-overflowHidden--mobileOnly");
  });
  $mainMenuDropdowns.on('hide.bs.collapse', function () {
    $body.removeClass("u-overflowHidden--mobileOnly");
  });
}
function bindAddToCartEvent() {
  var $addToCartBtns = $("[data-action=addtocart]");
  if ($addToCartBtns.length) {
    // Only do stuff if user is logged in (eg: #cartLink exists in DOM)

    $addToCartBtns.on("click", function () {});
  }
}
function showAddToCartQuantityForEDometic() {
  if ($("body").hasClass("is-edometiccatalogue")) {
    $.ajax({
      method: "POST",
      url: "/api/edometic/cartquantity"
    }).done(function (result) {
      if (result && result.Success) {
        updateVisualCartIcon(result.totalCartQuantity, false);
      }
    });
  }
}
function updateVisualCartIcon(numberOfItemsInCart) {
  var showAddedToCartMessageOverlay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var hasItemsInCart = numberOfItemsInCart > 0;
  $(".navigationMainComponent-cartQuantity").text(numberOfItemsInCart);
  $(".navigationMainComponent-cartQuantity").toggle(hasItemsInCart);
  $(".navigationMainComponent-cartWithItems").parent().parent().parent().toggle(hasItemsInCart);
  $(".navigationMainComponent-cart").parent().parent().toggle(!hasItemsInCart);
  //$(".navigationMainComponent-cartWithItemsMobile").parent().toggle(hasItemsInCart);
  //$(".navigationMainComponent-cartWithItemsMobile").toggle(hasItemsInCart);
  $(".navigationMainComponent-cartMobile").toggle(!hasItemsInCart);
  if (showAddedToCartMessageOverlay) {
    showAddedToCartMessage();
  }
}
function showAddedToCartMessage() {
  var $cartLink = $(".navbar-right .navigationMainComponent-cartMobileLink, .navigationMainComponent-cartWithItemsMobileLink, .navbar-right .navigationMainComponent-cartLink").filter(":visible").first();
  if ($cartLink.length) {
    var elAddedToCart = "<div id='addedToCart' class='navigationMainComponent-addedToCartMessage'>" + "<div class='navigationMainComponent-addedToCartHeader u-textBold20'>" + $cartLink.data("cartupdatedtext") + "</div>" + "<a href='" + $cartLink.data("addedtocartlink") + "' class='u-textSemi14'>" + $cartLink.data("addedtocartlinktext") + "<svg viewBox='0 0 9.4 13.7' class='navigationMainComponent-addedToCartCaret' xmlns='http://www.w3.org/2000/svg'>" + "<path d='M1.5 13.5L.2 12l6-5.1-6-5.2L1.5.2l7.7 6.7z'/>" + "</svg>" + "</a>" + "</div>";
    $(elAddedToCart).appendTo($cartLink);
    setTimeout(function () {
      $('#addedToCart').remove();
    }, 3000);
  }
}
function bindConfirmAlertMessageButton() {
  $(".navigationMainComponent-alertDismiss button").click(function (e) {
    var $container = $(this).closest(".navigationMainComponent-alert");
    $container.slideUp("slow");
    var formData = new FormData();
    formData.append('sitecoreItemId', $("body").data('sc-item'));
    formData.append('sitecoreLanguageId', $("body").data('sc-language'));
    $.ajax({
      url: "/api/edometicalertmessage",
      type: 'POST',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false
    });
  });
}
function bindAccountSelectorDropdown() {
  $('.navigationMainComponent-dropdownMenu--myDometicAccountDropdown').on('click', function (event) {
    var target = $(event.target);
    if (target.parents('.bootstrap-select.accountnumber').length === 0) {
      $('.bootstrap-select.accountnumber.open').removeClass('open');
    }
    event.stopPropagation();
  });
  if ($('.accountnumberSelectpicker').length) {
    $('.accountnumberSelectpicker').selectpicker({
      container: 'body'
    });
    $('body').on('click', function (event) {
      var target = $(event.target);
      if (target.parents('.bootstrap-select.accountnumber').length) {
        event.stopPropagation();
        $('.bootstrap-select.accountnumber.open').removeClass('open');
      }
    });
    $('.navigationMainComponent-navbarNavItem').on('hide.bs.dropdown', function () {
      $('.bootstrap-select.accountnumber.open').removeClass('open');
    });
    $("body").on('changed.bs.select', '.accountnumberSelectpicker', function (e, clickedIndex, newValue, oldValue) {
      //console.log(this.value, clickedIndex, newValue, oldValue);
      if (newValue) {
        accountSelector.submit($('#accountnumber option').eq(clickedIndex).val());
      }
    });
  }
}
function getFreightPriceLimit() {
  var formData = new FormData();
  formData.append('sitecoreItemId', $("body").data('sc-item'));
  formData.append('sitecoreLanguageId', $("body").data('sc-language'));
  $.ajax({
    url: "/api/freefreightnotification",
    type: 'POST',
    data: formData,
    dataType: 'json',
    processData: false,
    contentType: false
  }).done(function (result) {
    if (result !== null && result !== void 0 && result.Data && result.Data.length > 0) {
      $(".freightBanner-text").html(result.Data);
      $(".freightBanner").removeClass("hidden");
    }
  }).fail(function () {
    console.log("Failed to fetch free freight notification data.");
  });
}